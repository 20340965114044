<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Security</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            The UK security sector covers a wide range of services for physical and private security
                            needs. Physical security includes guarding, surveillance, and access control, regulated by
                            the Security Industry Authority (SIA). Guarding involves static guards, mobile patrols, and
                            response units, while surveillance uses CCTV and motion sensors. Private security firms
                            complement public law enforcement by providing event security, personal protection
                            (bodyguards), and corporate security solutions, including audits and risk assessments. They
                            often act as the first line of defence and support police during major events. Overall, the
                            sector is crucial for protecting individuals, businesses, and communities, adapting to
                            threats and using technology to enhance security.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-3xl lg:text-4xl font-semibold font_inter leading-[44px] mb-3">
                            Why consider a
                            role in the security sector?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">Considering a role in
                            physical and private security services can be highly rewarding due to the
                            constant demand for security personnel, which provides stable employment opportunities. The
                            sector offers a variety of roles, from security guarding
                            and surveillance to personal protection and corporate security solutions, allowing
                            individuals to find a niche that suits their skills and interests.
                            Additionally, working in security helps develop valuable skills such as situational
                            awareness, crisis management, and communication, which are transferable
                            to other career paths. Security roles also contribute significantly to public safety and
                            crime prevention, providing a sense of purpose and fulfilment as
                            security personnel act as the first line of defence, ensuring a safe environment for
                            everyone. </div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Security/Security 1.jpg"
                                alt="pathway-img" />
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/Security/Security 2.jpg"
                                alt="pathway-img" />
                        </div>
                        <div class="justify-start items-start gap-8 inline-flex">
                            <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                src="../../../../../assets/images/sector_pathway/images/Security/Security 3.jpg"
                                alt="pathway-img" />
                            <div
                                class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                    <div class=" flex-col justify-start items-start flex">
                                        <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                            £26,000</div>
                                        <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                            Average salary</div>
                                    </div>
                                    <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                        Professionals in the UK Security sector enjoy competitive salaries with
                                        entry level positions ranging from £18K to £25K. Some roles can come with
                                        salaries in excess of £50K depending on the role.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div class="text-center text-[#331643] text-5xl font-bold font_hindguntur leading-[64px]">
                                Is a career in the security sector for me? </div>
                            <div class="text-center text-[#4f3b5b] text-lg font-normal font_hindguntur leading-7">
                                A career in personal or private security can be an excellent fit for Service leavers for
                                several reasons:</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Transferable skills</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Discipline, situational awareness, crisis management and other skills gained
                                            through your military training are highly relevant within the security
                                            sector.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            High demand</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            High demand for Service leavers due to their reliability and expertise. The
                                            private
                                            security industry really values the experience and mindset of ex-forces
                                            personnel. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Diverse opportunities</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Diverse opportunities within the sector, offering a range of roles from
                                            close protection and
                                            event security to corporate security solutions allowing you to find a
                                            niche that suits your interests and skills. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Support and training</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Support and training through various programmes and initiatives, such as the
                                            Enhanced
                                            Learning Credits Administration Service (ELCAS) that provide financial
                                            support for further training and qualifications in security.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Sense of purpose</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Sense of purpose continuing to protect and serve in a civilian capacity can
                                            provide a
                                            fulfilling career path, maintaining the sense of duty and purpose
                                            experienced in the military. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-24 flex items-center justify-center">
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
            class="hidden sm:flex" />
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
            class="sm:hidden" />
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container lg:mb-24 mb-16">
            <div class="max_container">
                <div class="justify-between items-start lg:flex block mb-16">
                    <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">What
                        roles are available in the security sector?</div>
                    <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                        <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">There are
                            a variety of roles available in personal and private security, each with its own set of
                            responsibilities and requirements. Here are some key positions to consider:</div>
                    </div>
                </div>
                <div class="w-full relative">
                    <div class="carousel-container">
                        <div class="carousel-track">
                            <div *ngFor="let slide of slides" class="carousel-slide">
                                <div
                                    class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                    <div
                                        class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                            <div
                                                class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                {{ slide.title }}
                                            </div>
                                            <div
                                                class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                {{ slide.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Carousel navigation buttons -->
                        <div class="button-container">
                            <button id="slider-button-left"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                        alt="arrow-left"></div>
                            </button>
                            <button id="slider-button-right"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                        alt="arrow-right"></div>
                            </button>
                        </div>

                        <!-- Pagination -->
                        <div class="carousel-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container bg-[#724EB3]">
            <div class="max_container">
                <div class="lg:py-24 py-16">
                    <div class="px-8 justify-start items-start gap-16 lg:flex block">
                        <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                                <div>
                                    <img src="../../../../../assets/images/sector_pathway/Featured icon.svg" alt="icon">
                                </div>
                                <div
                                    class="self-stretch text-white text-3xl lg:text-4xl font-semibold font_hindguntur leading-[52px]">
                                    What's it like to work in the security sector?</div>
                            </div>
                            <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                Working in personal or private security can be both challenging and rewarding. Here are
                                some key aspects of what it's like:</div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Variety and flexibility</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Professionals in this field can work in diverse environments, ranging from
                                        corporate offices and residential buildings to providing personal protection for
                                        high-profile individuals. Additionally, many security jobs come with flexible
                                        hours, including night and weekend shifts, making it easier to balance work with
                                        other commitments.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Responsibilities</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Responsibilities involving tasks such as patrolling premises, monitoring surveillance systems,
                                        and responding to alarms. Security professionals are primarily responsible for
                                        ensuring the safety of both people and property. Additionally, they manage
                                        security incidents like trespassing or theft and coordinate with law enforcement
                                        when necessary.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Rewards</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Transport and logistics plays a crucial role in ensuring that products reach
                                        consumers, making your work essential and impactful. Many logistics roles offer
                                        competitive salaries, especially for specialized skills. Additionally, the field
                                        is constantly evolving, providing opportunities to work on innovative projects
                                        and improve processes.</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Skills and training</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Skills and training are important in the industry as security professionals need a range of
                                        transferable skills, such as situational awareness, crisis management, and
                                        effective communication, which are often drawn from military or law enforcement
                                        backgrounds. Continuous learning is also essential in this field, with ongoing
                                        training and certifications, like those from the Security Industry Authority
                                        (SIA) in the UK, being crucial for maintaining and enhancing their expertise.
                                    </div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Challenges</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Challenges will arise as security roles can involve high stress situations, especially in
                                        demanding positions like personal bodyguarding, which require long hours and
                                        constant vigilance. Additionally, staying updated with the latest security
                                        technologies, such as AI, is crucial for modern security professionals to
                                        effectively perform their duties.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Rewards</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Rewards and a strong sense of purpose from directly impacting safety and security, can
                                        provide a fulfilling sense of duty and accomplishment. There are also ample
                                        opportunities for career progression such as advancing into supervisory
                                        positions.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<vc-footer></vc-footer>