<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Charity</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            Working in the charity sector in the UK is a diverse and rewarding experience, offering
                            roles in fundraising, project management, marketing and more. The sector significantly
                            contributes to the economy, employing around 950,000 people. Key skills that may be required
                            for a role within the industry include strong communication, project management and a
                            passion for the sector. Job opportunities can be found on websites like Reed.co.uk,
                            CharityJob, Inicio and Third Sector Jobs, as well as through networking. While the work can
                            be fulfilling, it also comes with challenges such as competition and resource limitations,
                            especially in smaller organisations.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-3xl lg:text-4xl font-semibold font_inter leading-[44px] mb-3">
                            Why consider a
                            role in the charity sector?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">A Service leaver
                            should consider a role in the charity sector for several compelling
                            reasons: military-developed skills such as leadership, teamwork, problem-solving
                            and resilience are highly valued and directly applicable
                            to roles in project management, operations and more. The charity sector offers a sense of
                            purpose, allowing Service leavers to contribute to meaningful
                            causes and continue serving the community in a different capacity. Additionally, the sector
                            provides diverse opportunities, from frontline services to
                            strategic positions, ensuring that individuals can find roles that align with their
                            interests and strengths. The supportive and inclusive environment of
                            the charity sector can ease the transition from military to civilian life, with many
                            organisations offering training and development opportunities. Furthermore,
                            the charity sector is known for its strong networking and community, providing valuable
                            connections and a sense of belonging, which can be particularly important
                            for Service leavers.</div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="flex-col gap-8 flex">
                            <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                                <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                    src="../../../../../assets/images/sector_pathway/images/Charity/Charity 1.jpg"
                                    alt="pathway-img" />
                                <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                    src="../../../../../assets/images/sector_pathway/images/Charity/Charity 2.jpg"
                                    alt="pathway-img" />
                            </div>
                            <div class="justify-start items-start gap-8 inline-flex">
                                <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                    src="../../../../../assets/images/sector_pathway/images/Charity/Charity 3.jpg"
                                    alt="pathway-img" />
                                <div
                                    class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                    <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                        <div class=" flex-col justify-start items-start flex">
                                            <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                                £31,000</div>
                                            <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                                Average salary</div>
                                        </div>
                                        <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                            Professionals in the UK Charity sector enjoy competitive salaries with entry level positions ranging from £20K to
                                            £25K. Experienced professionals can earn more, with salaries in excess of £30K depending on the role.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div class="text-center text-[#331643] text-5xl font-bold font_hindguntur leading-[64px]">
                                Is a career in the charity sector for me?</div>
                            <div class="text-center text-[#4f3b5b] text-lg font-normal font_hindguntur leading-7">
                                Considering a career in the charity sector can be incredibly rewarding, but it's
                                important to reflect on a few key aspects to determine if it's the right fit for you:
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Passion for the cause</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Passion for the cause and a strong desire to make a positive impact on society would be a good
                                            reason to seek a role within the charity sector. Working for a charity
                                            often involves supporting causes you’re passionate about, whether it’s
                                            helping the homeless, protecting the environment or advocating for human
                                            rights.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Skills and interests</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            From fundraising and marketing to project management and direct service
                                            delivery, there are a variety of roles with different skillsets within
                                            this sector. Think about where your skills and interests lie. For
                                            example, if you enjoy organising events, a role in fundraising may be a
                                            good fit.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Work environment</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Work environment at charities can vary greatly due to size and structure. Some are large
                                            organisations with many employees, while others are small and
                                            community-focused. Consider what type of environment you thrive in.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Volunteering experience</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Experience can be gained with charities directly. If you’re unsure about whether
                                            this sector is a good fit for you, volunteering can be a great way to
                                            gain insight into the sector. Many charities offer volunteer roles that
                                            can provide valuable experience and networking opportunities.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-24 flex items-center justify-center">
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
            class="hidden sm:flex" />
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
            class="sm:hidden" />
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container lg:mb-24 mb-16">
            <div class="max_container">
                <div class="justify-between items-start lg:flex block mb-16">
                    <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">What
                        roles are available in the charity sector?</div>
                    <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                        <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">The
                            charity sector in the UK offers a diverse range of roles, catering to various skills and
                            interests. Here are some common types of roles you might find:</div>
                    </div>
                </div>
                <div class="w-full relative">
                    <div class="carousel-container">
                        <div class="carousel-track">
                            <div *ngFor="let slide of slides" class="carousel-slide">
                                <div
                                    class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                    <div
                                        class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                            <div
                                                class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                {{ slide.title }}
                                            </div>
                                            <div
                                                class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                {{ slide.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Carousel navigation buttons -->
                        <div class="button-container">
                            <button id="slider-button-left"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                        alt="arrow-left"></div>
                            </button>
                            <button id="slider-button-right"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                        alt="arrow-right"></div>
                            </button>
                        </div>

                        <!-- Pagination -->
                        <div class="carousel-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container bg-[#F9FAFB]">
            <div class="max_container">
                <div>
                    <div
                        class="lg:py-24 py-16 bg-gray-50 rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
                        <div class="self-stretch justify-between items-center lg:flex block">
                            <div class="max-w-[44rem] mb-8">
                                <div class="text-[#331643] text-[32px] font-bold font_hindguntur leading-[48px]">
                                    Featured jobs</div>
                                <div class="text-[#4f3b5b] text-base font-normal font_hindguntur leading-normal">Here
                                    are some featured roles in the UK charity sector along with their average salaries:
                                </div>
                            </div>
                            <div
                                class="px-4 py-2.5 bg-white rounded-full shadow border border-[#d2d6db] justify-center items-center gap-1.5 flex">
                                <div class="px-0.5 pt-[5px] justify-center items-center flex cursor-pointer text-[#384250] text-base font-medium font_hindguntur leading-normal"
                                    (click)="goToJobs()">
                                    View more jobs
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:gap-6 gap-4">
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Charity sector</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Policy and Research Officer</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £30,000-£40,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    is vital as they conduct research, develop policies and advocate for the cause.
                                    Gather and analyse data, draft proposals, ensure compliance and communicate
                                    findings. Requires strong analytical and project management skills. Salaries from
                                    £30k-£40k.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Charity sector</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Marketing and Communications Manager</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £35,000-£45,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    manages the public relations, social media and marketing campaigns. Crafts strategic
                                    plans to boost visibility and engagement, oversees content creation, manages media
                                    relations and coordinates promotional activities. Salaries range from £35k-£45k.
                                </div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Charity sector</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Volunteer Coordinator</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £24,000-£26,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    is crucial for supporting a charity’s mission by recruiting, training, and
                                    organising volunteers. Ensures a dedicated team for activities and initiatives,
                                    maintaining operations and achieving goals. Average salary: £24k-£26k.</div>
                            </div>
                            <div
                                class="grow shrink basis-0 px-6 pt-6 pb-7 bg-white rounded-2xl border border-gray-200 flex-col justify-start items-center gap-6 inline-flex">
                                <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                    <div class="self-stretch justify-between items-start inline-flex">
                                        <div class="grow shrink basis-0 justify-start items-start gap-4 flex">
                                            <div
                                                class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                <div
                                                    class="self-stretch text-[#331643] text-base font-normal font_hindguntur leading-normal">
                                                    Charity sector</div>
                                                <div
                                                    class="self-stretch text-[#331643] text-3xl font-semibold font_hindguntur leading-[44px]">
                                                    Fundraising Coordinator</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="self-stretch justify-start items-end inline-flex">
                                        <div class="h-7 justify-start items-center gap-6 flex">
                                            <div class="justify-start items-center gap-2 flex">
                                                <div class="w-5 h-5 relative"><img
                                                        src="../../../../../assets/images/sector_svg/currency-pound-circle.svg"
                                                        alt="currency-icon"></div>
                                                <div class="pt-1 justify-center items-center gap-2 flex">
                                                    <div
                                                        class="text-[#6c737f] text-base font-normal font_hindguntur leading-normal">
                                                        £20,000-£25,000 per annum</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="self-stretch text-[#141414] text-lg font-normal font_hindguntur leading-7">
                                    is essential for a charity’s financial health, they plan and execute fundraising
                                    activities, build donor relationships, and manage campaigns. Requires strong
                                    organisational and communication skills. Salaries range from £20k-£25k.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container bg-[#724EB3]">
            <div class="max_container">
                <div class="lg:py-24 py-16">
                    <div class="px-8 justify-start items-start gap-16 lg:flex block">
                        <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div class="self-stretch flex-col justify-start items-start gap-6 flex">
                                <div>
                                    <img src="../../../../../assets/images/sector_pathway/Featured icon.svg" alt="icon">
                                </div>
                                <div
                                    class="self-stretch text-white text-3xl lg:text-4xl font-semibold font_hindguntur leading-[52px]">
                                    What organisations support Service leaver recruitment? </div>
                            </div>
                            <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                Working in the charity sector as a Service leaver can be a fulfilling and impactful
                                career choice. Here are some organisations that support military recruitment:
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Cancer Research UK</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        funds research and offers roles from scientific officers to shop
                                        assistants.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        The National Trust</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        preserving historic places with opportunities for project officers,
                                        coordinators and administrators.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Oxfam</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        tackling poverty with roles in programme management, communications, logistics
                                        and administration.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Haig Housing</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        providing housing for ex-service personnel with roles in property management and
                                        support services.</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Save the Children</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        operating in 120 countries with positions in humanitarian work, HR and
                                        fundraising.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        The British Heart Foundation</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        leading the fight against coronary heart disease with opportunities in research
                                        and shop management.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<vc-footer></vc-footer>