<nav class="w-full">
  <div class="w-full home_sub_container items-center md:max-h-20 h-20">
    <div class="w-full xl:w-[1280px] lg:px-8 px-4">
      <div class="flex items-center justify-between">
        <div class="space-x-10 flex items-center">
          <div class="flex items-center">
            <a [routerLink]="['/home']">
              <img src="../../../../assets/images/Header-Footer_SVG/CTP-Logo-purple.svg" alt="nav-logo-lg">
            </a>
          </div>
          <ul class="hidden md:space-x-8 lg:flex md:items-center mb-0">
            <!-- PRE LOGIN About CTP  -->
            <li class="group relative cursor-pointer header_link" [routerLinkActiveOptions]="{exact: true}"
              routerLinkActive="active" (focusout)="onFocusOut($event)">
              <a class="flex items-center menu-hover  pt-1"
                (keydown.enter)="toggleDropdown($event, 'aboutDropdownOpen')" [attr.aria-haspopup]="true"
                [attr.aria-expanded]="aboutDropdownOpen">
                <div>About CTP</div>
                <mat-icon class="!mb-1">keyboard_arrow_down</mat-icon>
              </a>
              <div
                class="invisible absolute top-6 z-50 flex w-full md:w-96 max-w-96 flex-col bg-white shadow-md group-hover:visible border rounded-xl"
                [class.visible]="aboutDropdownOpen" [class.invisible]="!aboutDropdownOpen"
                (focusout)="onFocusOut($event)">
                <div class="px-5 py-6 space-y-1">
                  <div *ngFor="let preAbout of preAboutCTP"
                    class="flex p-3 cursor-pointer hover:bg-[#f2f2f2] hover:rounded-lg" routerLinkActive="active">
                    <a routerLink="/{{preAbout?.state }}">
                      <h5 class="header_nav_title">{{preAbout?.name}}</h5>
                      <p class="header_nav_subtitle">
                        {{preAbout?.description}} </p>
                      <a class="flex items-center space-x-1.5">
                        <p class="pt-[5px] ">Learn more</p>
                        <img src="../../../../assets/images/Header-Footer_SVG/arrow-right.svg"
                          alt="right-arrow-button1">
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="group relative cursor-pointer header_link" routerLinkActive="active"
              (focusout)="onFocusOut($event)">
              <a class="flex items-center menu-hover pt-1" [ngClass]="{'active-link': isCourseRouteActive()}"
                (keydown.enter)="toggleDropdown($event, 'trainingDropdownOpen')" tabindex="0"
                [attr.aria-haspopup]="true" [attr.aria-expanded]="trainingDropdownOpen">
                <div>Training & Events </div>
                <mat-icon class="!mb-1">keyboard_arrow_down</mat-icon>
              </a>
              <div
                class="invisible absolute top-6 z-50 flex w-96 max-w-96 flex-col bg-white shadow-md group-hover:visible border rounded-xl"
                [class.visible]="trainingDropdownOpen" [class.invisible]="!trainingDropdownOpen"
                (focusout)="onFocusOut($event)">
                <div class="px-5 py-6 space-y-1">
                  <div *ngFor="let preTrainingEvents of preTrainingAndEvents"
                    class="flex p-3 cursor-pointer hover:bg-[#f2f2f2] hover:rounded-lg" routerLinkActive="active">
                    <a routerLink="/{{preTrainingEvents?.state }}">
                      <h5 class="header_nav_title">{{preTrainingEvents.name}}</h5>
                      <p class="header_nav_subtitle">
                        {{preTrainingEvents.description}}
                      </p>
                      <a class="flex items-center space-x-1.5">
                        <p class="pt-[5px] ">Learn more</p>
                        <img src="../../../../assets/images/Header-Footer_SVG/arrow-right.svg"
                          alt="right-arrow-button1">
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <ng-container *ngFor="let preMenu of preMenuItems">
              <li class="header_link pt-1" routerLinkActive="active" *ngIf="!authenticationService.isLoggedIn">
                <a routerLink="/{{preMenu?.state }}">{{preMenu?.name}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of navigation">
              <li class="header_link pt-1"
                *ngIf="authenticationService.isLoggedIn && (item.featureId == 65 || item.featureId == 66 || item.featureId == 164 || item.featureId == 192 || item.featureId == 110 )"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" id="{{ item.name }}">
                <a routerLink="/{{item?.state }}">{{item?.name}}</a>
              </li>
            </ng-container>
            <li class="header_link pt-1" *ngIf="authenticationService.isLoggedIn" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'active-link': isDosRouteActive()}" id="dos">
              <a routerLink="/home/directory-of-services">Directory of Services</a>
            </li>
          </ul>
        </div>

        <div class="hidden lg:flex lg:items-center lg:space-x-4">
          <button [matMenuTriggerFor]="menu" class="relative rounded-full border hover:bg-gray-200 min-w-10"
            type="button">
            <img src="../../../../assets/images/Header-Footer_SVG/switch-horizontal-01.svg" alt="switch" class="p-2.5">
            <span *ngIf="compareList.length > 0" class="item-count-badge bg-purple-600">{{ compareList.length }}</span>
          </button>
          <mat-menu #menu="matMenu" class="p-4 mr-2">
            <ng-container *ngIf="compareList.length > 0; else noItems">
              <div class="flex justify-between items-start mb-4">
                <h5 class="text-[#331643] font_hindguntur font-semibold">Compare</h5>
                <button type="button" (click)="closeChat()">
                  <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-1">
                </button>
              </div>
              <div class="flex justify-center items-center flex-col space-y-2">
                <mat-menu-item mat-menu-item *ngFor="let course of compareList"
                  class="!flex !items-center !justify-between">
                  <p class="course_card_paragraph p-2 !flex !items-end">
                    {{ course.name | slice:0:22 }}<span *ngIf="course.name.length > 22">...</span>
                  </p>
                  <button mat-icon-button (click)="removeFromCompare(course.id)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-menu-item>
                <div class="w-full mt-2">
                  <button *ngIf="compareList.length >= 2" (click)="navigateToCompare()"
                    class="mt-4 w-full courses-button !max-w-full text-white bg-[#44185b] hover:bg-[#44185b] !max-h-12">
                    Compare courses
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-template #noItems>
              <div class="flex justify-between items-start">
                <h5 class="header_nav_link_sm mb-1 text-[20px] hover:!text-[#331643] text-[#331643] font_hindguntur">
                  Your compare list is
                  empty</h5>
                <button type="button" (click)="closeChat()" class="mt-2 mb-2">
                  <img src="../../../assets/images/ChatSVG/Button close X.svg" alt="close-button-1">
                </button>
              </div>
              <p>Select 2-3 courses to compare and find the right one for you</p>
            </ng-template>
            <div class="flex justify-center" *ngIf="compareList.length === 3">
              <img src="../../../../assets/icons/alert-circle.svg" class="w-8 h-8 mt-6 mr-3"
                alt="forgot-password-icon" />
              <p class="mt-2 text-sm">
                You have added the maximum of 3 courses. Please remove one to compare this course.
              </p>
            </div>
          </mat-menu>
          <button *ngIf="authenticationService.isLoggedIn" class="rounded-full border hover:bg-gray-200 min-w-10"
            type="button" (click)="openChat()">
            <img src="../../../../assets/images/Header-Footer_SVG/ChatIcon.svg" alt="chat" class="p-2.5">
          </button>
          <button *ngIf="!authenticationService.isLoggedIn"
            class="md:px-4 py-2.5 bg-[#562672] border border-[#562672] space-x-2.5 text-white w-full lg:w-fit hover:opacity-90 flex items-center justify-center rounded-full lg:h-[49px]"
            type="button" [routerLink]="['/home/sign-in']">
            Log In
          </button>
          <div class="group relative cursor-pointer">
            <a class="flex items-center menu-hover" routerLinkActive="active" tabindex="0"
              (keydown.enter)="toggleDropdown($event, 'profileDropdownOpen')">
              <div *ngIf="authenticationService.isLoggedIn"
                class="h-10 w-10 rounded-full border bg-[#F2F4F7] hover:bg-gray-200 flex items-center justify-center "
                type="button">
                <div class="font_inter font-semibold text-base text-[#667085] mt-0.5" *ngIf="userInitials">{{
                  userInitials }}</div>
              </div>
            </a>
            <div
              class="invisible absolute top-9 -right-[40px] !z-50 flex w-full md:w-[240px] max-w-60 flex-col bg-white shadow-md group-hover:visible border rounded-lg "
              [class.visible]="profileDropdownOpen" [class.invisible]="!profileDropdownOpen"
              (focusout)="onFocusOut($event)">
              <div class="flex items-center px-4 py-3 space-x-3 !cursor-default">
                <div *ngIf="authenticationService.isLoggedIn"
                  class="h-10 w-10 rounded-full border bg-[#F2F4F7] flex items-center justify-center" type="button">
                  <div class="font_inter font-semibold text-base text-[#667085] mt-0.5">{{ userInitials }}</div>
                </div>
                <div class="text-[#344054] font-semibold text_inter text-sm">{{fullname}}</div>
              </div>
              <hr class="py-1">
              <ul>
                <a class="header_profile_box_parent header_profile_box_link" [routerLink]="['/transition/user-profile']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <p>View profile</p>
                </a>
                <li>
                  <a class="header_profile_box_parent header_profile_box_link" id="changePassword"
                    [routerLink]="['/user-profile/change-password']" routerLinkActive="active">
                    <p>{{ "Change Password" }}</p>
                  </a>
                </li>
                <hr class="py-1">
                <ng-container *ngFor="let item of navigation">
                  <li id="{{ item.name }}"
                    *ngIf="authenticationService.isLoggedIn && (item.featureId == 63 || item.featureId == 67 )"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="header_profile_box_parent header_profile_box_link" [routerLinkActiveOptions]="{exact: true}"
                      routerLinkActive="active" routerLink="/{{ item.state }}" id="header-navigation-{{ item.id }}"
                      title="Go to {{ item.name }}">
                      {{item.name }}
                    </a>
                  </li>
                </ng-container>
                <ng-container *ngFor="let item of postHoverMenu">
                  <li id="{{ item.name }}" *ngIf="authenticationService.isLoggedIn"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="header_profile_box_parent header_profile_box_link" [routerLinkActiveOptions]="{exact: true}"
                      routerLinkActive="active" routerLink="/{{ item.state }}" id="header-navigation-{{ item.id }}"
                      title="Go to {{ item.name }}"> {{ item.name }}</a>
                  </li>
                </ng-container>
              </ul>
              <hr class="py-1">
              <a class="header_profile_box_parent header_profile_box_link mb-1" routerLinkActive="active"
                [routerLink]="['/home']" tabindex="0" (click)="onSignOut()" (keydown.enter)="onSignOut()"
                [routerLinkActiveOptions]="{exact: true}">
                Log Out
              </a>
            </div>
          </div>
        </div>

        <!-- FOR MOBILE DEVICE  -->
        <div class="lg:hidden">
          <button mat-icon-button class="hover:bg-slate-200" aria-label="Example icon-button with a menu"
            [matMenuTriggerFor]="menu1">
            <ng-container *ngIf="!isMenuOpen">
              <img src="../../../../assets/images/Header-Footer_SVG/hamburger.svg" alt="hamburger">
            </ng-container>
          </button>

          <mat-menu #menu1="matMenu"
            class="lg:hidden !min-w-[100%] !max-w-[100vh] !min-h-screen !max-h-screen !top-0 right-0 !fixed">
            <div class="flex flex-col justify-between lg:hidden space-y-6 h-[90vh] w-[99vw] relative">
              <div class="relative">
                <div
                  class="flex items-center justify-between min-h-20 px-4 border-b border-[#E5E7EB] sticky top-0 bg-white">
                  <img src="../../../../assets/images/Header-Footer_SVG/CTP-Logo-purple.svg" alt="sm-logo">
                  <button class="text-[#562672]" type="button">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <ul class="space-y-2 overflow-y-auto">
                  <li class="px-4 pt-6 block space-y-1" [routerLinkActiveOptions]="{exact: true}"
                    routerLinkActive="active">
                    <a class="header_nav_link_sm mb-1">About CTP </a>
                    <div *ngFor="let preAbout of preAboutCTP"
                      class="flex p-3 cursor-pointer hover:bg-[#f2f2f2] hover:rounded-lg" routerLinkActive="active">
                      <a routerLink="/{{preAbout?.state }}" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        <h5 class="header_nav_title">{{preAbout?.name}}</h5>
                        <p class="header_nav_subtitle">
                          {{preAbout?.description}} </p>
                        <a class="flex items-center space-x-1.5">
                          <p class="pt-[5px] header_lernmore_link">Learn more</p>
                          <img src="../../../../assets/images/Header-Footer_SVG/arrow-right.svg"
                            alt="right-arrow-button1">
                        </a>
                      </a>
                    </div>
                  </li>

                  <li class="px-4 block space-y-1" routerLinkActive="active">
                    <a [ngClass]="{'active-link': isCourseRouteActive()}" class="header_nav_link_sm mb-1">Training &
                      Events
                    </a>
                    <div *ngFor="let pretrainingandevent of preTrainingAndEvents"
                      class="flex p-3 cursor-pointer hover:bg-[#f2f2f2] hover:rounded-lg" routerLinkActive="active">
                      <a routerLink="/{{pretrainingandevent?.state }}">
                        <h5 class="header_nav_title">{{pretrainingandevent?.name}}</h5>
                        <p class="header_nav_subtitle">
                          {{pretrainingandevent?.description}} </p>
                        <a class="flex items-center space-x-1.5">
                          <p class="pt-[5px] header_lernmore_link">Learn more</p>
                          <img src="../../../../assets/images/Header-Footer_SVG/arrow-right.svg"
                            alt="right-arrow-button1">
                        </a>
                      </a>
                    </div>
                  </li>

                  <ng-container *ngFor="let preMenuMobile of preMenuItems">
                    <li class="px-4 block py-1.5" *ngIf="!authenticationService.isLoggedIn" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: false}">
                      <a routerLink="/{{preMenuMobile?.state }}" class="header_nav_link_sm">{{preMenuMobile.name}}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let postMenuItem of postMenuItems">
                    <li *ngIf="authenticationService.isLoggedIn " routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}" id="{{ postMenuItem.name }}" class="px-4 py-1.5">
                      <a class="header_nav_link_sm hover:no-underline" routerLink="/{{ postMenuItem.state }}"
                        id="header-navigation-{{ postMenuItem.id }}" [routerLinkActiveOptions]="{exact: false}">{{
                        postMenuItem.name }}
                      </a>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let items of navigation">
                    <li *ngIf="authenticationService.isLoggedIn && (items.featureId == 63 || items.featureId == 67)"
                      routerLinkActive="active" id="{{ items.name }}" class="px-4 py-1.5"
                      [routerLinkActiveOptions]="{exact: false}">
                      <a class="header_nav_link_sm hover:no-underline"
                        [ngClass]="{ 'navigation-right': items.positionRight }" routerLink="/{{ items.state }}"
                        id="header-navigation-{{ items.id }}" title="Go to {{ items.name }}">{{ items.name }}
                      </a>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let postMenuHoverItem of postHoverMenu">
                    <li *ngIf="authenticationService.isLoggedIn" routerLinkActive="active"
                      id="{{ postMenuHoverItem.name }}" class="px-4 py-1.5" [routerLinkActiveOptions]="{exact: false}">
                      <a class="header_nav_link_sm hover:no-underline"
                        [ngClass]="{ 'navigation-right': postMenuHoverItem.positionRight }"
                        routerLink="/{{ postMenuHoverItem.state }}" id="header-navigation-{{ postMenuHoverItem.id }}"
                        title="Go to {{ postMenuHoverItem.name }}">{{ postMenuHoverItem.name }}
                      </a>
                    </li>
                  </ng-container>
                  <li *ngIf="authenticationService.isLoggedIn" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: false}" id="change-password" class="px-4 py-1.5">
                    <a class="header_nav_link_sm hover:no-underline" routerLink="/user-profile/change-password"
                      id="header-navigation-dos" [routerLinkActiveOptions]="{exact: false}">Change password
                    </a>
                  </li>
                  <hr>
                </ul>
                <div class="flex items-center justify-center w-full">
                  <div
                    class="flex items-center justify-center flex-col py-6 px-4 space-y-4 bg-[#F9FAFB] rounded-lg w-full">
                    <button class="header_sm_nav_btn">
                      <img src="../../../../assets/images/Header-Footer_SVG/switch-horizontal-01.svg" alt="compare">
                      <div class="header_sm_nav_btn_text">Compare courses</div>
                    </button>
                    <button [matMenuTriggerFor]="menu" class="header_sm_nav_btn"
                      *ngIf="authenticationService.isLoggedIn" (click)="openChat()">
                      <img src="../../../../assets/images/Header-Footer_SVG/message-chat-circle.svg" alt="chat">
                      <div class="header_sm_nav_btn_text">Message Career Consultant
                      </div>
                    </button>
                    <button class="header_sm_nav_btn" [routerLink]="['/transition/user-profile']"
                    *ngIf="authenticationService.isLoggedIn">
                    <div class="header_sm_nav_btn_text">View profile</div>
                    </button>
                  </div>
                </div>
              </div>
              <div class="p-6 sticky bottom-0 bg-white box-with-top-shadow ">
                <button type="button" *ngIf="authenticationService.isLoggedIn" (click)="onSignOut()" class="form_button"
                  routerLinkActive="active" tabindex="=0" (keydown.enter)="onSignOut()">
                  {{ "Log Out" }}
                </button>
                <button *ngIf="!authenticationService.isLoggedIn" [routerLink]="['/home/sign-in']"
                  class="form_button cursor-pointer">
                  Log in
                </button>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</nav>
