import { Component, HostListener, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication/authentication.service';
import { Url } from './shared/constants/url-constants';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppUpdateService } from './core/pwa-services/app-update.service';
import { UserActivityService } from './home/user-activity/user-activity.service';
import { Observable, Subscription, timer } from 'rxjs';
import { ConfirmService } from './shared/components/confirm-box/confirm-box.service';
import { TabsService } from './shared/components/tabs/tabs.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from './shared/service/dialog.service';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  private timerSubscription: Subscription;
  private timer: Observable<number>;
  logo: string;
  clientDetails;
  @ViewChild('drawer') drawer!: MatDrawer;
  currentPath: string;
  showHeader:boolean = false;
  applyHeight: boolean;
  isDialogOpen = false;
  constructor(
    private readonly appUpdateService: AppUpdateService,
    private readonly title: Title,
    public readonly authenticationService: AuthenticationService,
    private readonly renderer: Renderer2,
    private router: Router,
    private readonly userActivityService: UserActivityService,
    private readonly zone: NgZone,
    private readonly confirmService: ConfirmService,
    private readonly tabService: TabsService,
    private readonly dialogRef: MatDialog,
    private readonly dialogService: DialogService
  ) {
    this.appUpdateService.checkForUpdates();
    this.title.setTitle('Reed CTP');
  }
  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.userActivityService.lastActivity = new Date().getTime();
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
        this.startTimer();
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Filter for NavigationEnd events
    )?.subscribe((event: NavigationEnd) => {
      this.currentPath = event.urlAfterRedirects;
      this.showHeader = false;
      if(this.currentPath.includes('?')){
        this.currentPath = event.urlAfterRedirects.split('?')[0];
      }
      const hiddenHeaderPaths = [
        '/home/course-payment',
        '/home/event-payment',
        '/home/workshop-payment',
        '/home/forgot-password',
        '/home/booking-status',
        '/home/list-courses',
        '/home/course-search',
        '/home/workshop-search',
        '/home/event-search',
        '/home/list-events',
        '/home/list-workshops',
        '/set-password',
        '/home/sign-in',
        '/home/event-workshop-status'
      ];
      this.showHeader = !hiddenHeaderPaths.includes(this.currentPath);
    });
    if (!environment.enableCtrl) {
      this.disableControls();
    }

      this.authenticationService?.getClientDetails()?.subscribe(data => {
        this.clientDetails = data;
        localStorage.setItem('clientId', this.clientDetails.id);
        localStorage.setItem('logoPath', this.clientDetails.logoPath);
        localStorage.setItem('ApplicationID',this.clientDetails.appId);
        if(this.clientDetails.moodleUrl){// if moodle flag disabled , dont pass moodle url
          localStorage.setItem('moodleUrl',this.clientDetails.moodleUrl);
        }
         
        localStorage.setItem('isSelfRegisterAllowed',this.clientDetails.isSelfRegisterAllowed)
        document.documentElement.style.setProperty('--primary-color', this.clientDetails.primaryAppColour);
        document.documentElement.style.setProperty('--secondary-color', this.clientDetails.secondaryAppColour);
      });

      this.authenticationService.currentLogo.subscribe(logo => {    
        this.logo = logo +  localStorage.getItem('logoPath');
    });
    this.authenticationService.chatEvent$.subscribe(data => {
      this.openChat(data);
    });
  }

  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (let i = 0; i < forbiddenKeys.length; i++) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }
  openChat(event) {
    if (event) {
      document.body.classList.add('no-scroll');
      this.drawer.open();
    } else {
      this.drawer.close();
    }
    this.drawer.openedChange.subscribe((isOpened: boolean) => {
      if (!isOpened) {
        document.body.classList.remove('no-scroll');
      }
    });
  }

  chatCloseEvent(event){
    this.drawer.close();
  }

  resolveSessionParams() {
    let sessionParams;
    if (localStorage.getItem("sessionParams")) {
      sessionParams = JSON.parse(atob(localStorage.getItem("sessionParams")));
    } else {
      sessionParams = {
        idleTime: 600,
        inactiveNotificationTime: 120,
        heartbeat: 60,
      };
    }
    return sessionParams;
  }


  startTimer() {
    const sessionParams = this.resolveSessionParams();
    this.timer = timer(0, 1000);
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = this.timer.subscribe(n => {
      localStorage.setItem('lastActivity', '' + this.userActivityService.lastActivity);

      if ((new Date().getTime() - +localStorage.getItem('lastActivity')) / 1000 >
        (sessionParams.idleTime - sessionParams.inactiveNotificationTime) && !this.isDialogOpen && this.authenticationService.isLoggedIn) {
        this.isDialogOpen = true;

        this.zone.run(() => {
          this.dialogService.closeAllDialogs();

          this.confirmService.confirm({
            header: 'Your session will expire!',
            message: 'You will be signed out in ',
            showTimer: true,
            countdown: sessionParams.inactiveNotificationTime,
            acceptLabel: 'Continue',
            rejectLabel: 'Sign out',
            accept: () => {
              this.isDialogOpen = false;
              this.userActivityService.lastActivity = new Date().getTime();
            },
            reject: () => {
              this.tabService.emitSignOutClicked(true);
              this.isDialogOpen = false;
              this.dialogRef.closeAll();
              localStorage.removeItem('lastActivity');
              this.timerSubscription.unsubscribe();
              this.authenticationService.signout().subscribe();
            }
          });
        });
      }
      if(this.authenticationService.isLoggedIn)
      {
        this.sendHeartbeat(n, sessionParams.heartbeat);
      }
    });

  }
  sendHeartbeat(time, heartbeat) {
    if (time % heartbeat === 0) {
      this.userActivityService.checkHeartbeat().subscribe(
        response => {
          if (response.isRefreshRequred === true) {
            this.authenticationService.refreshToken().subscribe();
          }
        }
      );
    }
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:scroll', ['$event'])
  @HostListener('window:message', ['$event'])
  setUserLastActivity() {
    this.userActivityService.lastActivity = new Date().getTime();
  }

}
