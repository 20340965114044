import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private dialogCloseSource = new Subject<void>();
    dialogClose$ = this.dialogCloseSource.asObservable();

    constructor(private dialog: MatDialog) { }

    closeAllDialogs() {
        this.dialog.closeAll();
        this.dialogCloseSource.next();
    }
}