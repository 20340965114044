<div class="w-full">
    <div class="w-full">
        <div class="w-full home_sub_container px-4">
            <div class="w-full max-w-[1376px] rounded-2xl bg-[#724EB3] flex justify-center items-center ">
                <div class="max_container">
                    <div class="lg:py-24 py-16 px-4 lg:px-0">
                        <div class="text-center text-[#ccc4ee] text-lg font-medium font_hindguntur leading-7 mb-4">
                            Sector Guides</div>
                        <div
                            class="text-center text-[#f7f6fc] font-bold font_hindguntur lg:text-7xl text-5xl lg:leading-[88px] leading-[64px] mb-2">
                            Wind Energy</div>
                        <div class="text-center text-[#f7f6fc] text-lg font-normal font_hindguntur leading-7">
                            The wind energy sector in the UK is a dynamic and rapidly growing field, offering a wealth
                            of opportunities for those looking to transition from military service to civilian careers.
                            As the world grapples with the challenges of climate change, the UK’s commitment to wind
                            energy solutions positions it as a global leader in this crucial industry. The UK’s abundant
                            wind resources make it a prime location for both onshore and offshore wind turbines. The
                            government’s goal to power all households with wind energy by 2030 underscores the sector’s
                            significance. This guide aims to provide British military Service leavers with a
                            comprehensive pathway to a rewarding career in wind energy, highlighting the sector’s
                            potential, key employers, necessary skills and training opportunities.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full">
        <div class="w-full home_sub_container">
            <div class="max_container">
                <div class="lg:py-24 py-16 px-6 lg:px-0">
                    <div class="max-w-[48rem] mb-16">
                        <div class="text-[#331643] text-3xl lg:text-4xl font-semibold font_inter leading-[44px] mb-3">
                            Why consider a
                            role in wind energy?</div>
                        <div class="text-[#503C5C] text-lg font-normal font_hindguntur leading-7">Considering a role in
                            wind energy can be highly rewarding for several
                            reasons: firstly, wind energy is a clean and renewable source of power, which means working in this field contributes directly to
                            reducing carbon emissions and combating climate change. The industry is rapidly growing,
                            creating numerous job opportunities and offering long term career stability.
                            Wind energy roles often come with competitive salaries and the chance to work with cutting
                            edge technology. Wind energy projects can be implemented in various settings, including
                            remote and rural areas; the sector supports local communities by generating economic growth
                            and providing jobs, making it a versatile and impactful career choice.
                        </div>
                    </div>
                    <div class="flex-col gap-8 flex">
                        <div class="justify-start items-start gap-8 inline-flex overflow-hidden">
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[30.625rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/WindEnergy/Wind Energy 1.jpg"
                                alt="pathway-img" />
                            <img class="rounded-2xl w-1/2 sm:w-full overflow-x-hidden max-w-[43.375rem] h-[20rem] object-cover"
                                src="../../../../../assets/images/sector_pathway/images/WindEnergy/Wind Energy 2.jpg"
                                alt="pathway-img" />
                        </div>
                        <div class="justify-start items-start gap-8 inline-flex">
                            <img class="rounded-2xl w-full max-w-[43.375rem] h-[20rem] object-cover hidden lg:flex"
                                src="../../../../../assets/images/sector_pathway/images/WindEnergy/Wind Energy 3.jpg"
                                alt="pathway-img" />
                            <div
                                class="px-12 py-[52px] bg-[#7953c2] rounded-2xl justify-center items-center flex w-full max-w-[30.625rem] h-[20rem]">
                                <div class="grow shrink basis-0  flex-col justify-start items-start gap-4 inline-flex">
                                    <div class=" flex-col justify-start items-start flex">
                                        <div class=" text-white text-7xl font-bold font_hindguntur leading-[88px]">
                                            £34,000</div>
                                        <div class=" text-white text-lg font-medium font_hindguntur leading-7">
                                            Average salary</div>
                                    </div>
                                    <div class=" text-white text-base font-normal font_hindguntur leading-normal">
                                        Professionals in the UK Wind Sector enjoy competitive salaries with entry level positions ranging from £21K to
                                        £28K. Some roles can come with salaries in excess of £40K depending on the role.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-6 lg:px-0">
                    <div class="flex justify-center">
                        <div class="max-w-[37rem] flex flex-col mb-16">
                            <div class="text-center text-[#331643] lg:text-5xl text-[32px] font-bold font_hindguntur leading-[64px]">
                                Is a career in wind energy for me?</div>
                            <div class="text-center text-[#4f3b5b] text-lg font-normal font_hindguntur leading-7">
                                Deciding if a career in wind energy is right for you depends on several factors,
                                including your interests, skills and career goals. Here are some considerations to help
                                you determine if this field might be a good fit:</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Interest in renewable energy</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            If you have a strong interest in renewable energy and sustainability, a
                                            career in wind energy could be a perfect fit. Working in this field
                                            allows you to contribute to a cleaner, greener future by harnessing the
                                            power of wind to generate electricity. Your passion for sustainability
                                            can drive meaningful change and help reduce carbon emissions, making a
                                            positive impact on the environment. By working in wind energy, you are
                                            directly contributing to a more sustainable world and supporting the
                                            transition to renewable energy sources.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Relevant skills and education</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Many roles in wind energy require technical skills in engineering,
                                            mechanics or electronics. Consider whether you have these skills or are
                                            willing to acquire them. Additionally, think about whether you have or
                                            are willing to pursue the necessary education, such as degrees in
                                            engineering, environmental science or related fields. Acquiring these
                                            skills and education can open numerous opportunities in the wind energy
                                            sector and help you contribute effectively to this growing industry.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Work environment preferences</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Some roles in wind energy, like wind turbine technicians, involve
                                            working outdoors and at height, so it’s important to consider if you are
                                            comfortable with this type of work environment. On the other hand, there
                                            are roles such as policy analysts or data analysts that are more
                                            office-based. Reflect on where you prefer to work and what type of
                                            environment suits you best when considering a career in wind energy.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Financial considerations</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            When considering a career in wind energy, it’s important to research the
                                            typical salaries for various roles to ensure they meet your financial
                                            needs
                                            and expectations. An entry level role will pay less than a more
                                            experienced
                                            role, so understanding these salary ranges can help you make an informed
                                            decision about pursuing a career in this growing and impactful industry.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Career goals</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            Consider whether you see yourself building a long-term career in the
                                            renewable energy sector, taking into account the growth opportunities
                                            and stability this field offers. Additionally, think about whether you
                                            are motivated by the idea of making a positive impact on the environment
                                            and society. A career in wind energy can provide both personal
                                            fulfilment and the chance to contribute to a sustainable future.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Job market and opportunities</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            The renewable energy sector is growing, with increasing demand for
                                            skilled professionals. It’s important to research the job market in your
                                            area or where you are willing to relocate. Additionally, research
                                            specific roles and opportunities available in wind energy to see if they
                                            align with your career aspirations. This can help you determine if a
                                            career in this field is a good fit for you.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="max-w-[37.25rem] flex-col justify-start items-start inline-flex">
                            <div class="self-stretch justify-start items-start gap-4 inline-flex">
                                <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                                    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
                                        <div
                                            class="self-stretch text-[#331643] text-xl font-semibold font_hindguntur leading-loose">
                                            Personal attributes</div>
                                        <div
                                            class="self-stretch text-[#503C5C] text-base font-normal font_hindguntur leading-normal">
                                            The wind energy sector is dynamic and evolving, requiring professionals
                                            who are
                                            adaptable and open to continuous learning. Many roles in this field also
                                            demand
                                            strong problem-solving skills and the ability to work through complex
                                            challenges.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="my-16 lg:my-24 flex items-center justify-center">
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new.svg" alt="shapes-logo-img-jobs"
            class="hidden sm:flex" />
        <img src="../../../../assets/images/JobsImageSVG/shape-frame-new-sm.svg" alt="shapes-logo-img-jobs-sm"
            class="sm:hidden" />
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container lg:mb-24 mb-16">
            <div class="max_container">
                <div class="justify-between items-start lg:flex block mb-16">
                    <div class="max-w-[592px] text-[#331643] text-5xl font-semibold font_hindguntur leading-[64px]">What
                        roles are available in wind energy?</div>
                    <div class="max-w-[486px] flex-col justify-start items-start gap-4 inline-flex">
                        <div class="self-stretch text-[#503C5C] text-lg font-normal font_hindguntur leading-7">Roles in
                            the wind energy sector can be quite diverse, encompassing various positions and
                            responsibilities.
                            Here are some common roles and their responsibilities:
                        </div>
                    </div>
                </div>
                <div class="w-full relative">
                    <div class="carousel-container">
                        <div class="carousel-track">
                            <div *ngFor="let slide of slides" class="carousel-slide">
                                <div
                                    class="w-[798px] pl-[34px] pr-8 pt-[34px] pb-[33px] bg-[#7953c2] rounded-lg justify-center items-center inline-flex">
                                    <div
                                        class="w-[732px] self-stretch p-4 bg-[#967dd5] rounded-lg flex-col justify-start items-start gap-4 inline-flex">
                                        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
                                            <div
                                                class="self-stretch lg:h8 text-white text-3xl font-semibold font_hindguntur leading-[44px]">
                                                {{ slide.title }}
                                            </div>
                                            <div
                                                class="self-stretch text-white text-xl font-normal font_hindguntur leading-loose">
                                                {{ slide.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Carousel navigation buttons -->
                        <div class="button-container">
                            <button id="slider-button-left"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button prev-button">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-left.svg"
                                        alt="arrow-left"></div>
                            </button>
                            <button id="slider-button-right"
                                class="w-10 lg:h10 p-2 rounded-[40px] border border-[#d2d6db] carousel-button next-button ">
                                <div class="w-6 lg:h6 relative flex-col justify-start items-start flex"><img
                                        src="../../../../../assets/images/sector_svg/arrow-narrow-right.svg"
                                        alt="arrow-right"></div>
                            </button>
                        </div>

                        <!-- Pagination -->
                        <div class="carousel-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class="w-full home_sub_container bg-[#724EB3]">
            <div class="max_container">
                <div class="lg:py-24 py-16">
                    <div class="px-8 justify-start items-start gap-16 lg:flex block">
                        <div class="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div class="self-stretch  flex-col justify-start items-start gap-6 flex">
                                <div>
                                    <img src="../../../../../assets/images/sector_pathway/Featured icon.svg" alt="icon">
                                </div>
                                <div
                                    class="self-stretch text-white text-4xl font-semibold font_hindguntur leading-[52px]">
                                    What's it like to work in wind energy?</div>
                            </div>
                            <div class="self-stretch text-[#e2dff5] text-lg font-normal font_hindguntur leading-7">
                                Working in the wind energy sector can be both rewarding and challenging. Here are some
                                insights into what it's like:</div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Dynamic work environment</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        A variety of roles, ranging from fieldwork as a wind turbine technician to
                                        office-based positions like data analysis or policy development. For those
                                        involved in turbine maintenance, the job often requires spending a significant
                                        amount of time outdoors, frequently in remote locations and at considerable
                                        heights. This diversity ensures that there are opportunities to match different
                                        interests and skill sets within the industry, making it an appealing choice for
                                        those who enjoy both technical challenges and the chance to work in changing
                                        environments.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Impactful work</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Impactful work allowing you to be part of a sector that plays a crucial role in reducing carbon
                                        emissions and promoting sustainable energy. Additionally, wind energy projects
                                        often benefit local communities through job creation and economic development,
                                        making your contributions valuable not only to the environment but also to
                                        society.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Technological innovation</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Technological innovation includes utilising cutting-edge technology in areas such as turbine design and
                                        energy storage solutions. This dynamic field requires continuous learning to
                                        stay updated with the latest technologies and best practices, making it an
                                        excellent choice for lifelong learners who are passionate about staying ahead in
                                        a rapidly evolving industry.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Job satisfaction</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Job satisfaction due to the strong sense of purpose that comes from contributing to a sustainable
                                        future within the renewable energy sector. The industry also offers numerous
                                        opportunities for innovation and problem-solving, allowing individuals to tackle
                                        complex challenges and drive meaningful change.</div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grow shrink basis-0 flex-col justify-start items-start gap-8 lg:flex block mt-12 lg:mt-0 space-y-10 lg:space-y-0">
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Collaborative atmosphere</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Teamwork is essential. Many projects require close collaboration with
                                        engineers, environmental scientists, project managers, and other professionals.
                                        Additionally, you’ll often engage with various stakeholders, including
                                        government agencies, local communities, and private companies, ensuring that
                                        projects are successful and beneficial to all parties involved.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Challenges</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Challenges within various roles can be physically demanding, requiring climbing turbines
                                        and working in various weather conditions. Additionally, navigating regulatory
                                        requirements and ensuring compliance can be complex and time-consuming, adding
                                        another layer of difficulty to the job.</div>
                                </div>
                            </div>
                            <div class="self-stretch  flex-col justify-start items-center gap-5 flex">
                                <div class="self-stretch  flex-col justify-start items-center gap-2 flex">
                                    <div class="self-stretch text-white text-lg font-medium font_hindguntur leading-7">
                                        Career growth</div>
                                    <div
                                        class="self-stretch text-[#e2dff5] text-base font-normal font_hindguntur leading-normal">
                                        Career growth due to the rapid expansion of the industry, providing numerous opportunities to
                                        excel and advance within the sector. As you enter this growing industry, you’ll
                                        have the chance to develop a diverse skill set, ranging from technical expertise
                                        to project management and environmental assessment. This dynamic field not only
                                        promises a promising career trajectory but also equips you with valuable skills
                                        that are in high demand.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<vc-footer></vc-footer>